import Asset from 'api/types/models/asset'
import authImage from 'resources/authImage.png'
import kicallLogo from 'resources/kicall-logo.png'

export function getThemeBackground (scannedAsset: Asset | null, location: string): string {
  // Assume safe location to NOT use theme
  if (location !== '/') {
    return authImage
  }

  if (scannedAsset?.theme?.background?.url) {
    return scannedAsset.theme.background.url
  }

  return authImage
}

export function getThemeLogo (asset: Asset | null) {
  if (asset?.theme?.logo?.url) {
    return asset?.theme.logo.url
  }

  return kicallLogo
}