import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import Device from 'api/types/models/device'
import DeviceRelationships from 'enums/DeviceRelationship'
import useErrorHandler from 'hooks/useErrorHandler'
import Button from '../../../button'
import Input from '../../../form/input'
import Phone, { PhoneInputProps } from '../../../form/phone'
import Dropdown from 'components/form/dropdown'
import FormLayout from '../layouts/FormLayout'
import Alert from 'components/alerts'
import ComboBox from 'components/form/combobox'
import { useAdminGetAssetsQuery, useAdminGetUsersQuery } from 'api'
import Select from 'react-select'
import FormError from 'components/error'

type FormInputs = {
  asset_ids: number[]
  user_id: number
  name: string
  phone: string
  country_code: string
  relationship: string
}

type FormProps = {
  device?: Device
  onSubmitError?: FetchBaseQueryError | SerializedError | undefined
  onSubmit: (data: FormInputs) => void
  onSubmitBtnText?: string
  onSubmitLoading?: boolean
}

const AdminDeviceForm = ({ device, onSubmitError, onSubmit, onSubmitBtnText = 'Create', onSubmitLoading = false }: FormProps) => {
  const [searchParams] = useSearchParams()
  const errorHandler = useErrorHandler()

  const [relationship, setRelationship] = useState<string>('Other')
  const [selectedUser, setSelectedUser] = useState<number>(0)

  const { register, handleSubmit, setError, clearErrors, setValue, getValues, watch, formState: { errors } } = useForm<FormInputs>({
    defaultValues: {
      name: device?.name,
      user_id: device?.user_id,
      asset_ids: device ? device.assets.map((asset) => asset.id) : [],
      country_code: device?.country_code,
      phone: device?.phone,
      relationship: device?.relationship,
    },
  })

  const { isLoading: isUsersLoading, data: usersResult } = useAdminGetUsersQuery({}, {})
  const { isLoading: isAssetsLoading, data: assetResult } = useAdminGetAssetsQuery({ filter: { user_id: [selectedUser] } }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (device && !selectedUser) {
      setSelectedUser(device.user_id)
    }
  })

  useEffect(() => {
    if (onSubmitError) {
      errorHandler(onSubmitError, setError)
    }
  }, [onSubmitError])

  const submitWrapper = (data: FormInputs) => {
    data.relationship = relationship

    return onSubmit(data)
  }

  const issueTypeOptions = (Object.entries(DeviceRelationships).map(([key, value]) => {
    return (
      <Dropdown.Item key={key} onClick={() => setRelationship(value)}>
        {value}
      </Dropdown.Item>
    )
  }))

  const handlePhoneOnChange = (phone: PhoneInputProps) => {
    setValue('phone', phone.phone)
    setValue('country_code', phone.country_code)

    if (errors.phone || errors.country_code) {
      clearErrors(['country_code', 'phone'])
    }
  }

  return (
    <FormLayout>
      <form onSubmit={handleSubmit(submitWrapper)}>
        {
          device?.is_asset_owner &&
          <Alert type="info" message="This person is the owner of the KiCall. To change this information, the owner's details should be updated directly." />
        }

        <FormLayout.Block>
          <FormLayout.Title
            title="Third Party"
            subtitle="Third party's information"
          />

          <FormLayout.Group
            label="Name"
            htmlFor="name"
          >
            <Input {...register('name')} error={errors?.name?.message} disabled={device?.is_asset_owner} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Phone"
            htmlFor="phone"
          >
            <Phone
              defaultPhone={getValues('phone')}
              defaultCountryCode={getValues('country_code')}
              onChange={(phone) => handlePhoneOnChange(phone)}
              error={errors?.phone?.message || errors?.country_code?.message} disabled={device?.is_asset_owner}
            />
          </FormLayout.Group>

          <FormLayout.Group
            label="User"
            htmlFor="userId"
            description="Set the user this person will be linked to"
          >
            <ComboBox
              isLoading={isUsersLoading}
              options={usersResult?.data && usersResult.data.map((user) => ({ text: user.full_name, value: user.id }))}
              onChange={(option) => {
                if (option) {
                  setValue('user_id', parseInt(option.value as string))
                  setSelectedUser(parseInt(option.value as string))
                  clearErrors('user_id')
                }
              }}
              defaultValue={getValues('user_id') ?? undefined}
              placeholder="Search for a user"
            />
            <FormError text={errors?.user_id?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Assets"
            htmlFor="asset_ids"
            description="Set which assets this person has access to, based on the user selection"
          >
            <Select
              isMulti
              isLoading={isAssetsLoading}
              placeholder="User assets"
              classNamePrefix="react-select"
              className="react-select-container !max-w-none"
              options={assetResult && assetResult.data.map((asset) => ({ value: asset.id, label: asset.description ?? 'Unnamed Asset' }))}
              onChange={(options) => {
                setValue('asset_ids', options.map((option) => (option.value)))
                clearErrors('asset_ids')
              }}
              value={
                assetResult?.data && watch('asset_ids') &&
                assetResult.data.filter((asset) => [...getValues('asset_ids')].includes(asset.id)).map((asset) => ({ value: asset.id, label: asset.description }))
              }
            />
            <FormError text={errors?.asset_ids?.message} />
          </FormLayout.Group>

          <FormLayout.Group
            label="Relationship"
            htmlFor="relationship"
          >
            <Dropdown
              {...register('relationship')}
              trigger={<div>{relationship ?? 'Select Issue Type'}</div>}
              error={errors?.relationship?.message}
              disabled={device?.is_asset_owner}>
              {issueTypeOptions}
            </Dropdown>
          </FormLayout.Group>
        </FormLayout.Block>

        <FormLayout.Footer>
          {
            searchParams.get('asset') ? (
              <Button href={`/admin/dashboard/assets/manage/${searchParams.get('asset')}`} variant="secondary">
                Back to Asset
              </Button>
            ) : (
              <Button href="/admin/dashboard/devices/browse" variant="secondary">
                Cancel
              </Button>
            )
          }
          <Button type="submit" isLoading={onSubmitLoading}>{onSubmitBtnText}</Button>
        </FormLayout.Footer>

      </form>
    </FormLayout>
  )
}

export default AdminDeviceForm